import { JsonProperty, Serializable } from "typescript-json-serializer"
import DiamondFile from "@/types/DiamondFile"
import { fullWidth } from "@/types/helper"

@Serializable()
export default class Branch {
  @JsonProperty("branch_id") id!: number

  @JsonProperty() branch!: string

  @JsonProperty() address!: string

  @JsonProperty() tel!: string

  @JsonProperty() fax!: string

  @JsonProperty() lat!: number

  @JsonProperty() lng!: number

  @JsonProperty() status!: string

  @JsonProperty() lang!: string

  @JsonProperty({ type: DiamondFile }) file: DiamondFile = new DiamondFile()

  @JsonProperty("place_name") placeName!: string

  @JsonProperty("place_id") placeId!: string

  @JsonProperty({ name: "i_frame", beforeDeserialize: fullWidth }) iFrame!: string
}
