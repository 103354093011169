



















































































import { Component } from "vue-property-decorator"
import ViewWrapper from "@/views/ViewWrapper.vue"
import BranchComponent from "@/components/ContactUs/BranchComponent.vue"
import ContactUsComponent from "@/components/ContactUs/ContactUsComponent.vue"
import MapDialog from "@/components/Global/MapDialog.vue"
import { mixins } from "vue-class-component"
import TabMixin from "@/types/TabMixin"
import BreakpointMixin from "@/types/BreakpointMixin"
import InputMixin from "@/types/InputMixin"
import _ from "lodash"
import Branch from "@/types/ContactUs/Branch"

@Component({
  name: "ContactUsPage",
  components: { MapDialog, ViewWrapper }
})
export default class ContactUsPage extends mixins(TabMixin, BreakpointMixin, InputMixin) {
  private tab = 0

  private mapDialog = false

  private mapContent = ""

  getTabClass(idx: number) {
    let cls = ""
    if (this.tab === idx) {
      cls = cls.concat("custom-active-tab font-weight-semi-bold")
    } else {
      cls = cls.concat("font-weight-light")
    }
    return cls
  }

  private generateRoute(idx: number) {
    return {
      name: "ContactUs",
      query: {
        lang: this.$i18n.locale,
        id: `${idx}`
      }
    }
  }

  private viewMap(branch: Branch) {
    this.mapContent = branch.iFrame
    this.mapDialog = true
  }

  private get currentComponent() {
    const idx = _.findIndex(this.menus, ["id", this.tab])
    return this.menus[idx].component
  }

  private menus = [
    {
      label: "contactUs.contact.title",
      id: 0,
      component: ContactUsComponent,
      hover: false
    },
    {
      label: "contactUs.branches.title",
      id: 1,
      component: BranchComponent,
      hover: false
    }
  ]

  mounted() {
    if ((this.$route.query && this.$route.query.id && this.$route.query.id === "0") || this.$route.query.id === "1") {
      const id = parseInt(this.$route.query.id as string)
      this.tab = id
    }
  }
}
