


































































import { Component, Watch } from "vue-property-decorator"
import Branch from "@/types/ContactUs/Branch"
import ContactUsApi from "@/api/ContactUs/ContactUsApi"
import _ from "lodash"
import { deserialize } from "typescript-json-serializer"
import ViewWrapper from "@/views/ViewWrapper.vue"
import loader from "@/plugins/googleMaps"
// import MapIcon from "@/assets/icons/map.png"
import { mixins } from "vue-class-component"
import BreakpointMixin from "@/types/BreakpointMixin"

@Component({
  name: "BranchComponent",
  components: { ViewWrapper }
})
export default class BranchComponent extends mixins(BreakpointMixin) {
  // private mapIcon = MapIcon

  private branches: Branch[] = []

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange() {
    // Router from vue-router
    this.fetchData()
  }

  private fetchData() {
    ContactUsApi.listBranches()
      .then(({ data }) => {
        this.branches = _.map(data, (d) => deserialize<Branch>(d, Branch))
      })
      .then(() => loader.load())
      .then(() => {
        // const markerIcon = {
        //   url: markerIconUrl,
        //   // eslint-disable-next-line no-undef
        //   scaledSize: new google.maps.Size(90, 144)
        // }
        _.forEach(this.branches, (branch, idx) => {
          let { lat, lng } = branch
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          lat = +lat!
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          lng = +lng!
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const div = document.getElementById(`branchMap${idx}`)! as HTMLElement
          // eslint-disable-next-line no-undef,@typescript-eslint/no-unused-vars
          const map = new google.maps.Map(div, {
            draggableCursor: "pointer",
            center: { lat, lng },
            zoom: 15,
            clickableIcons: false,
            disableDefaultUI: true,
            streetViewControl: false,
            disableDoubleClickZoom: true,
            fullscreenControl: false,
            panControl: false,
            mapTypeControl: false,
            scaleControl: false,
            gestureHandling: "none",
            zoomControl: false
          })

          // eslint-disable-next-line no-undef,@typescript-eslint/no-unused-vars
          // const marker = new google.maps.Marker({
          //   icon: markerIcon,
          //   clickable: false,
          //   position: { lat, lng },
          //   map
          // })
        })
      })
  }

  private get mapSize() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) {
      return "269px"
    }
    if (bp.mdAndUp) {
      return "250px"
    }
    if (bp.smAndDown) {
      return "150px"
    }
    return "269px"
  }

  toggleMapDialog(branch: Branch) {
    this.$emit("view-map", branch)
  }

  // eslint-disable-next-line class-methods-use-this
  getFileName(branch: Branch): string {
    return `${branch.branch}${branch.file?.fileExtension}`
  }

  // eslint-disable-next-line class-methods-use-this
  downloadBranchImage(idx: number) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const aTag = document.getElementById(`branchDownload${idx}`)!
    aTag.click()
  }

  mounted() {
    this.fetchData()
  }

  get largeBtn() {
    return this.$vuetify.breakpoint.mdAndUp
  }
}
